body {
    color: var(--color-text-default);
    font-family: var(--font-default);
    font-weight: 400;
    font-size: var(--font-size-default);
    line-height: calc(32/18);
}

b,
strong,
.strong {
    font-weight: var(--font-weight-bold);
}

.font-medium { font-weight: 500;}
.font-regular {font-weight: 400;}

.letter-spacing-0 {letter-spacing: 0;}
.letter-spacing-sm {letter-spacing: calc(1.7rem / 16);}
.letter-spacing {letter-spacing: calc(3rem / 16);}
.letter-spacing-lg {letter-spacing: calc(5rem / 16);}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin-top: 0;
    display: block;
    font-family: var(--font-default);
}

h1, .h1 {
    font-size: calc(32rem/16);
    line-height: calc(42/ 32);
    letter-spacing: calc(4rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(58rem/16);
        line-height: calc(74/58);
        letter-spacing: calc(5.44rem / 16);
    }
}
h2, .h2 {
    font-size: calc(30rem/16);
    line-height: calc(38/30);
    letter-spacing: calc(3.65rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(42rem/16);
        line-height: calc(54/42);
    }
}
h3, .h3 {
    font-weight: 300;
    font-size: calc(26rem/16);
    line-height: calc(41/32);
    letter-spacing: calc(2rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(32rem/16);
    }
}
h4, .h4 {
    font-size: calc(24rem/16);
    line-height: calc(35/28);
    letter-spacing: calc(2rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(28rem/16);
    }
}
h5, .h5 {
    font-size: calc(22rem/16);
    line-height: 1.25;
    letter-spacing: calc(1.5rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(24rem/16);
    }
}
h6, .h6 {
    font-size: calc(20rem/16);
}

.intro {
    font-size: calc(18rem / 16);
    line-height: calc(24/18);
    letter-spacing: calc(1rem / 16);

    @media screen and (min-width: 768px){
        font-size: calc(22rem / 16);
        line-height: calc(36/22);
    }
}