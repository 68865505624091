.content-box {
    border: calc(.5rem / 16) solid var(--color-light-grey);
    border-radius: calc(2rem / 16);
    background: rgba(255,255,255,0.6);
    box-shadow: 0 calc(5rem / 16) calc(15rem / 16) 0 rgba(0,0,0,0.2);
    padding: calc(30rem / 16) calc(25rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(50rem / 16);
    }
}
.content-box__detail {
    line-height: calc(24/18);
}
.content-box__detail + .content-box__detail {
    margin-top: calc(22rem / 16);
}