.download-item {
    background: #F0F0F0;
    border-radius: calc(6rem / 16);
    overflow: hidden;
    padding: calc(14rem / 16) calc(10rem / 16);

    @media screen and (min-width: 768px) {
        padding: 0;
        padding-right: calc(10rem / 16);
    }

    @media screen and (min-width: 1200px) {
        padding-right: calc(24rem / 16);
    }
}
.download-item:hover {
    color: var(--color-primary);
}
.download-item__detail {
    @media screen and (min-width: 768px) {
        padding: calc(5rem / 16) calc(10rem / 16);
    }

    @media screen and (min-width: 1200px) {
        padding: calc(5rem / 16) calc(24rem / 16);
    }
}
.download-item + .download-item {
    margin-top: calc(8rem / 16);
}
.download-item__title__icon {
    font-size: calc(24rem / 16);
}
.download-item__title__filename {
    word-break: break-word;
}