.form-errors {
    color: var(--color-danger);
    font-size: calc(12rem / 16);
}
.form-errors:empty {
    display: none;
}
.form-errors ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.has-error .form-control { border-color: var(--color-danger);}
.has-success .form-control { border-color: var(--color-success);}