.custom-radio {
    font-size: calc(16rem/16);
    line-height: calc(20/16);
    padding-left: calc(28rem / 16);
}
.custom-radio:before,
.custom-radio:after {
    content: " ";
    display: table;
}.custom-radio:after {
     clear: both;
 }
.custom-radio__input {
    position: absolute;
    width: calc(1rem/16);
    height: calc(1rem/16);
    margin: calc(-1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.custom-radio__box {
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    border: calc(1rem/16) solid var(--color-light-grey);
    background: #fff;
    width: calc(18rem/16);
    height: calc(18rem/16);
    border-radius: 50%;
}
.custom-radio__box:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 37.5%;
    height: 37.5%;
    border-radius: 50%;
    background: var(--color-primary);
    visibility: hidden;
}
.custom-radio__input:checked ~ .custom-radio__box:before {
    visibility: visible;
}
.custom-radio__input:focus ~ .custom-radio__box {
    border-color: var(--color-primary);
}
.has-error .custom-radio__box {
    border-color: var(--color-danger)
}
.custom-radio__text {
    display: block;
}