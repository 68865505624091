.label-badge {
    border-radius: calc(2rem / 16);
    color: var(--color-primary);
    border: 1px solid var(--color-primary);
    padding: calc(2rem / 16) calc(24rem / 16);
    font-size: calc(18rem / 16);
    line-height: calc(26/18);
    letter-spacing: 0;
}
.label-badge--blue {
    color: var(--color-blue);
    border-color: var(--color-blue);
}
.label-badge--green {
    color: var(--color-green);
    border-color: var(--color-green);
}
.label-badge--orange {
    color: var(--color-orange);
    border-color: var(--color-orange);
}
.label-badge--blue-light {
    color: var(--color-blue-light);
    border-color: var(--color-blue-light);
}
