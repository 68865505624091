.breadcrumb-container + .slide {
    margin-top: 0;
}
.breadcrumb {
    margin: calc(30rem / 16) 0;
    color: var(--color-text-default);
    font-size: calc(14rem / 16);
    font-weight: var(--font-weight-bold);
    letter-spacing: calc(1.8rem / 16);

    @media screen and (min-width: 768px) {
        margin: calc(30rem / 16) 0;
        font-size: calc(16rem / 16);
        line-height: calc(23/20);
    }
}
.breadcrumb-item {
    position: relative;
}
.breadcrumb-item+.breadcrumb-item {
    padding-left: calc(15rem / 16);

    @media screen and (min-width: 768px) {
        padding-left: calc(22rem / 16);
    }
}
.breadcrumb-item+.breadcrumb-item:before {
    content: '';
    width: 1px;
    top: calc(2rem / 16);
    bottom: calc(2rem / 16);
    background: var(--color-text-default);
    position: absolute;
    left: calc(6rem / 16);
    padding: 0;

    @media screen and (min-width: 768px) {
        left: calc(10rem / 16);
    }
}
.breadcrumb-item.active {
    font-family: var(--font-default-bold);
    font-weight: 400;
    color: var(--color-primary);
}

.breadcrumb-info {
    display: block;
    font-size: calc(16rem / 16);
    transform: rotate(-90deg) translateY(50%);
    transform-origin: left center;
    position: fixed;
    top: 50%;
    left: 0;
    z-index: 2;

    @media screen and (min-width: 1500px) {
        left: calc(90rem / 16);
    }
}