.console {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;

    @media screen and (min-width: 768px) {
        left: auto;
        bottom: auto;
        top: 50%;
        transform: translateY(-50%);
        width: calc(160rem / 16);
    }

    @media screen and (min-width: 1400px) {
        width: calc(212rem / 16);
    }

    @media screen and (max-width: 767px) {
        display: flex;
        box-shadow: 0 0 calc(10rem / 16) 0 rgba(0,0,0,0.2);
    }
}
.console__btn {
    height: calc(60rem / 16);
    line-height: calc(20/24);
    padding: calc(16rem / 16);
    width: 100%;

    @media screen and (min-width: 768px) {
        box-shadow: 0 calc(5rem / 16) calc(15rem / 16) 0 rgba(0,0,0,0.2);
        border-top-left-radius: calc(10rem / 16);
        border-bottom-left-radius: calc(10rem / 16);
        height: calc(70rem / 16);
        padding: 0 calc(20rem / 16);
        font-size: calc(18rem / 16);
    }

    @media screen and (min-width: 1400px) {
        height: calc(100rem / 16);
        font-size: calc(24rem / 16);
        padding: 0 calc(20rem / 16) 0 calc(40rem / 16);
    }
}
@media screen and (min-width: 768px) {
    .console__btn:not(:last-child) {
        margin-bottom: calc(10rem / 16);
    }
}
.console__btn__icon {
    font-size: calc(30rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(26rem / 16);
    }

    @media screen and (min-width: 1400px) {
        font-size: calc(30rem / 16);
    }
}