.teaser-slider {
    margin: 0 calc(-16rem / 16);

    @media screen and (min-width: 768px) {
        margin: 0;
        padding: 0 calc(24rem / 16);
    }
}
.teaser-slider .slider-item {
    padding: 0 calc(16rem / 16);
}
.teaser-slider .slick-track{
    display: flex;
}
.teaser-slider .slick-slide{
    display: flex;
    height: auto;
/*    align-items: center; //optional*/
/*    justify-content: center; //optional*/
}
