html {
    -ms-overflow-style: scrollbar; /*prevent scrollbar from overlapping content in IE*/
}
body {
    position: relative;
}
@media screen and (min-width: 768px) and (max-width: 991px) {
    body {
        width: calc(982rem/16);
    }
}
@media screen and (max-width: 767px) {
    body {
        min-width: calc(320rem/16);
        max-width: 100%;
        overflow-x: hidden;
    }
}
.page-wrapper {
    overflow: hidden;
}
.container.container {
    /*max-width: calc(1672rem/16);*/ /* 1640px container breite */
    max-width: calc(1572rem/16); /* 1540px container breite */
    width: 100%;
}
.container-md {
    max-width: calc(1360rem / 16);
    margin: 0 auto;
}
.container-narrow {
    max-width: calc(1082rem / 16);
    margin: 0 auto;
}
.container-xs {
    max-width: calc(804rem / 16);
    margin: 0 auto;
}
@media screen and (max-width: 767px){
    .container--no-padding-xs {
        padding: 0;
    }
}