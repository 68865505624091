.select {
    position: relative;
}
.select>select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}
.select select::-ms-expand {
    display: none;
}
.select:before {
    font-family: "iconfont";
    content: var(--icon-select);
    color: var(--color-text-default);
    speak: none;
    position: absolute;
    pointer-events: none;
    font-size: calc(8rem / 16);
    top: 50%;
    right: calc(15rem / 16);
    transform: translateY(-50%);
}
select.form-control:not([size]):not([multiple]) {
    height: calc(50rem / 16);
}