@media screen and (max-width: 767px) {
    .text-with-media__media {
        width: 100%;
        margin-bottom: 0;
    }
}

@media screen and (min-width: 768px) {
    .text-with-media {
        overflow: hidden;
    }
    .text-with-media__media-container {
        float: right;
        margin-left: calc(30rem/16);
        max-width: 48.6%;
    }
    .text-with-media__media {
        display: block;
        margin-bottom: calc(10rem/16);
        max-width: 100%;
        height: auto;
    }
    .text-with-img--media-left .text-with-media__media-container {
        float: left;
        margin-right: calc(30rem/16);
        margin-left: 0;
    }
    .text-with-media__media.text-with-media__media--video {
        width: calc(400rem/16);
        height: auto;
        margin-top: calc(10rem/16);
    }
}

.text-with-media__media-container + .wysiwyg {
    margin-top: calc(8rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: calc(30rem / 16);
    }
}