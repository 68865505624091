.slider__arrow.slick-disabled {
    pointer-events: none;
}
.slider__arrow.slick-disabled .slider__arrow__icon {
    color: var(--color-text-muted);
}

.slider--arrows-centered .slider__arrow {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: 1;
}
.slider--arrows-centered .slider__arrow.slick-next {
    right: 0;
    left: auto;
}

/* slick dots */
.slick-dots {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;
    font-size: 0;
    margin-top: calc(44rem / 16)  /* teaser slider ?  */;
}
.slick-dots li {
    display: inline-block;
}
.slick-dots li:not(:last-child) {
    margin-right: calc(12rem / 16);
}
.slick-dots button {
    border: none;
    background: none;
    padding: 0;
    width: calc(12rem / 16);
    height: calc(12rem / 16);
    border-radius: 50%;
    cursor: pointer;
    background: var(--color-light-grey);
    transition: background 200ms ease-in-out;
}
.slick-dots .slick-active button {
    background: var(--color-light);
}