.custom-checkbox {
    font-size: calc(16rem/16);
    line-height: calc(20/16);
    padding-left: calc(26rem / 16);
    position: relative;
}
.custom-checkbox+.custom-checkbox, .custom-checkbox + div > .custom-checkbox {
    margin-top: calc(10rem / 16);
}
.custom-checkbox:before,
.custom-checkbox:after {
    content: " ";
    display: table;
}
.custom-checkbox:after {
    clear: both;
}
.custom-checkbox__input {
    position: absolute;
    width: calc(1rem/16);
    height: calc(1rem/16);
    margin: calc(-1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.custom-checkbox__box {
    position: absolute;
    left: 0;
    top: calc(1rem / 16);
    overflow: hidden;
    border: calc(1rem / 16) solid var(--color-light-grey);
    background: #fff;
    width: calc(18rem/16);
    height: calc(18rem/16);
    border-radius: calc(4rem / 16);
}
.custom-checkbox__box:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: calc(7rem/16);
    color: var(--color-primary);
    visibility: hidden;
}
.custom-checkbox__input:checked ~ .custom-checkbox__box:before {
    visibility: visible;
}
.custom-checkbox__input:focus ~ .custom-checkbox__box {
    border-color: var(--color-primary);
}
.has-error .custom-checkbox__box {
    border-color: var(--color-danger)
}
.custom-checkbox__text {
    display: block;
    overflow: hidden;
}
.custom-checkbox__text small {
    font-size: calc(12rem / 16);
    line-height: calc(14/12);
    display: inline-block;
}