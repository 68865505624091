.page-heading {
    margin-top: calc(30rem / 16);
    margin-bottom: calc(30rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(95rem/16);
        margin-bottom: calc(75rem/16);
    }
}

.page-heading__title + .page-heading__intro {
    margin-top: calc(20rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: calc(60rem / 16);
    }
}
@media screen and (max-width: 767px) {
    .page-heading__title {
        word-break: break-word;
    }
}
.page-heading + .slide {
    margin-top: 0;
}