.horizontal-teaser {
    border: 1px solid var(--color-light);
    border-radius: calc(6rem / 16);
    letter-spacing: calc(1rem / 16);
    line-height: calc(30/18);
}
.horizontal-teaser + .horizontal-teaser {
    margin-top: calc(16rem / 16);
}
.horizontal-teaser__body {
    padding: calc(14rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(15rem / 16) calc(24rem / 16) calc(15rem / 16) 0;
    }
}
.horizontal-teaser__body__date {
    letter-spacing: calc(2.3rem / 16);
    margin-top: calc(20rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: calc(12rem / 16);
    }
}
.horizontal-teaser__body__title {
    font-size: calc(26rem / 16);
    letter-spacing: calc(2.9rem / 16);
    line-height: calc(42/32);
    @media screen and (min-width: 768px) {
        font-size: calc(32rem / 16);
        letter-spacing: calc(4rem / 16);
    }
}