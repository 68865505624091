.navbar {
    position: relative;
    z-index: 3;
    background: #fff;
    height: calc(70rem / 16);
    padding: calc(10rem / 16) calc(24rem / 16) calc(10rem / 16) calc(16rem / 16);

    @media screen and (min-width: 768px) {
        height: calc(100rem / 16);
        padding: 0;
        box-shadow: 0 calc(5rem / 16) calc(15rem / 16) 0 rgba(0,0,0,0.2);
    }
}
.navbar-brand {
    @media screen and (min-width: 768px) {
        min-width: calc(150rem / 16);
    }
    @media screen and (min-width: 1200px) {
        min-width: calc(210rem / 16);
    }
}
.navbar-collapse {
    display: block;
}
.nav-link {
    transition: color 200ms ease-in-out;
    font-size: calc(24rem / 16);
    line-height: calc(31/24);
    letter-spacing: calc(1rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(18rem / 16);
        line-height: calc(23/18);
        letter-spacing: calc(.75rem / 16);
        padding: calc(25rem / 16) 0;
    }

    @media screen and (min-width: 1200px) {
        font-size: calc(20rem / 16);
    }
}
.navbar-nav .nav-item > .nav-link {
    padding-left: 0;
    padding-right: 0;
}
.nav-link:hover {
    color: var(--color-primary);
}
.nav-item {
    position: relative;
}
.nav-item:not(:last-child) {
    @media screen and (min-width: 768px) {
        margin-right: calc(20rem / 16);
    }
    @media screen and (min-width: 1200px) {
        margin-right: calc(32rem / 16);
    }
}
.nav-item__subnav {
    display: none;
    background-color: #fff;
    color: var(--color-grey);
    font-size: calc(18rem / 16);
    letter-spacing: calc(1rem / 16);
    padding-top: calc(8rem / 16);

    @media screen and (min-width: 768px) {
        padding: 0;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        width: calc(270rem / 16);
        border-radius: calc(8rem / 16);
        box-shadow: 0 calc(3rem / 16) calc(15rem / 16) 0 rgba(0,0,0,.1);
    }
}
.nav-item.is-open .nav-item__subnav {
    display: block;
}
@media screen and (min-width: 768px) {
    .nav-item:hover .nav-item__subnav {
        display: block;
    }
    .nav-item__subnav:before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        width: calc(20rem / 16);
        height: calc(20rem / 16);
        background: #fff;
        transform: translateX(-50%) translateY(-50%) rotate(45deg);
        box-shadow: 0 calc(5rem / 16) calc(15rem / 16) 0 rgba(0,0,0,.1);
        z-index: -1;
    }
    .nav-item__subnav__list {
        background-color: #fff;
        padding: calc(24rem / 16) 0;
        border-radius: calc(8rem / 16);
    }
    .nav-item__subnav__list li + li {
        margin-top: calc(4rem / 16);
    }
}

.nav-item__subnav--sm {
    @media screen and (min-width: 768px) {
        width: calc(150rem / 16);
    }
}
.nav-link-text__icon {
    vertical-align: -.15em;
    margin-left: calc(2rem / 16);
}