@font-face {
    font-family: "iconfont";
    src: url('/cache-buster-1728402964113/static/build/icons/font/iconfont.eot?#iefix') format('eot'),
    url('/cache-buster-1728402964113/static/build/icons/font/iconfont.woff') format('woff'),
    url('/cache-buster-1728402964113/static/build/icons/font/iconfont.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
}

.icon {
                    display: inline-block;
                    font-family: "iconfont";
                    speak: none;
                    font-style: normal;
                    font-weight: normal;
                    line-height: 1;
                    text-rendering: auto;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    transform: translate(0, 0);
                }

.icon-lg {
                    font-size: 1.3333333333333333em;
                    line-height: 0.75em;
                    vertical-align: -15%;
                }
.icon-2x { font-size: 2em; }
.icon-3x { font-size: 3em; }
.icon-4x { font-size: 4em; }
.icon-5x { font-size: 5em; }
.icon-fw {
                    width: 1.2857142857142858em;
                    text-align: center;
                }

.icon-ul {
                    padding-left: 0;
                    margin-left: 2.14285714em;
                    list-style-type: none;
                }
.icon-ul > li {
                    position: relative;
                }
.icon-li {
                    position: absolute;
                    left: -2.14285714em;
                    width: 2.14285714em;
                    top: 0.14285714em;
                    text-align: center;
                }
.icon-li.icon-lg {
                                        left: -1.85714286em;
                                    }

.icon-rotate-45 {
                    transform: rotate(45deg);
                }
.icon-rotate-90 {
                    transform: rotate(90deg);
                }
.icon-rotate-180 {
                    transform: rotate(180deg);
                }
.icon-rotate-270 {
                    transform: rotate(270deg);
                }
.icon-flip-horizontal {
                    transform: scale(-1, 1);
                }
.icon-flip-vertical {
                    transform: scale(1, -1);
                }
:root .icon-rotate-45,
                      :root .icon-rotate-90,
                                            :root .icon-rotate-180,
                                                                  :root .icon-rotate-270,
                                                                                        :root .icon-flip-horizontal,
                                                                                                              :root .icon-flip-vertical {
                                                                                                                                        filter: none;
                                                                                                                                    }

.icon-addthis:before { content: "\EA01" }
.icon-arrow-small:before { content: "\EA02" }
.icon-arrow:before { content: "\EA03" }
.icon-calendar:before { content: "\EA04" }
.icon-check:before { content: "\EA05" }
.icon-close:before { content: "\EA06" }
.icon-doc:before { content: "\EA07" }
.icon-download:before { content: "\EA08" }
.icon-elements:before { content: "\EA09" }
.icon-facebook:before { content: "\EA0A" }
.icon-fullscreen:before { content: "\EA0B" }
.icon-google-plus:before { content: "\EA0C" }
.icon-instagram:before { content: "\EA0D" }
.icon-jpg:before { content: "\EA0E" }
.icon-k-sign:before { content: "\EA0F" }
.icon-linkedin:before { content: "\EA10" }
.icon-mail:before { content: "\EA11" }
.icon-pdf:before { content: "\EA12" }
.icon-phone:before { content: "\EA13" }
.icon-pinterest:before { content: "\EA14" }
.icon-select:before { content: "\EA15" }
.icon-slider-arrow:before { content: "\EA16" }
.icon-ticket:before { content: "\EA17" }
.icon-tickets:before { content: "\EA18" }
.icon-twitter:before { content: "\EA19" }
.icon-whatsapp:before { content: "\EA1A" }
.icon-world:before { content: "\EA1B" }


        :root {
--icon-addthis: "\EA01";
    --icon-arrow-small: "\EA02";
    --icon-arrow: "\EA03";
    --icon-calendar: "\EA04";
    --icon-check: "\EA05";
    --icon-close: "\EA06";
    --icon-doc: "\EA07";
    --icon-download: "\EA08";
    --icon-elements: "\EA09";
    --icon-facebook: "\EA0A";
    --icon-fullscreen: "\EA0B";
    --icon-google-plus: "\EA0C";
    --icon-instagram: "\EA0D";
    --icon-jpg: "\EA0E";
    --icon-k-sign: "\EA0F";
    --icon-linkedin: "\EA10";
    --icon-mail: "\EA11";
    --icon-pdf: "\EA12";
    --icon-phone: "\EA13";
    --icon-pinterest: "\EA14";
    --icon-select: "\EA15";
    --icon-slider-arrow: "\EA16";
    --icon-ticket: "\EA17";
    --icon-tickets: "\EA18";
    --icon-twitter: "\EA19";
    --icon-whatsapp: "\EA1A";
    --icon-world: "\EA1B";
    
}