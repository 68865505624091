.img-slide {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow: hidden;
}
.img-slide:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: .56;
    background: linear-gradient(312.31deg, #23252A 0%, rgba(152,23,17,0.89) 100%);
}
.img-slide--primary:before {
    background: linear-gradient(135deg, #981711 0%, #D91107 100%);
    opacity: .6;
}
.img-slide__content {
    padding: calc(30rem / 16) 0 calc(30rem / 16);
    position: relative;
    font-size: calc(20rem / 16);
    line-height: calc(26/20);
    letter-spacing: calc(2rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(75rem / 16) 0;
    }

    @media screen and (min-width: 1400px){
        padding: calc(100rem / 16) 0;
    }
}
.img-slide__content__title {
    font-size: calc(36rem/16);
    line-height: calc(46/36);
    letter-spacing: calc(1.5rem / 16);
    margin-bottom: calc(12rem / 16);

    @media screen and (min-width: 1600px) {
        font-size: calc(40rem/16);
        line-height: calc(44/42);
        letter-spacing: calc(6rem / 16);
        margin-bottom: calc(30rem / 16);
    }
}
.img-slide__content__text {
    padding-bottom: calc(20rem / 16);

    @media screen and (min-width: 1400px) {
        padding: 0 calc(20rem / 16) calc(30rem / 16);
    }
}
.img-slide__content__highlight {
    position: relative;
    font-size: calc(26rem / 16);
    line-height: calc(34/26);
    letter-spacing: calc(3.5rem / 16);
    padding-left: calc(34rem / 16);

    @media screen and (min-width: 1200px) {
        padding-left: calc(40rem / 16);
    }
}
.img-slide__content__highlight__icon {
    position: absolute;
    left: 0;
    top: calc(4rem / 16);
    font-size: calc(22rem / 16);
}

/* small img slide */
.img-slide--sm .img-slide__content {
    font-size: calc(18rem / 16);
    line-height: calc(23/18);
    letter-spacing: calc(1.2rem / 16);
    padding: calc(38rem / 16) calc(16rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(50rem / 16) calc(25rem / 16);
    }

    @media screen and (min-width: 1200px) {
        padding: calc(50rem / 16) 0;
    }
}
.img-slide--sm .img-slide__content__title {
    font-size: calc(40rem / 16);
    line-height: calc(42/40);
    letter-spacing: calc(3rem / 16);
    margin-bottom: 0;

    @media screen and (min-width: 768px) {
        font-size: calc(46rem / 16);
        line-height: calc(61/46);
        letter-spacing: calc(3.6rem / 16);
    }
}