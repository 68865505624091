.form-control {
    border-radius: calc(2rem / 16);
    background: #fff;
    border-color: var(--color-light-grey);
    height: calc(50rem / 16);
    padding: calc(10rem / 16) calc(16rem / 16);
    color: var(--color-text-default);
    font-size: calc(18rem / 16);
    line-height: calc(28/18);
    font-weight: var(--font-weight-bold);
}
.form-control:focus {
    background-color: #fff;
    border-color: var(--color-primary);
}
.form-control::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: var(--color-grey);
}
.form-control::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: var(--color-grey);
    opacity:  1;
}
.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--color-grey);
}

.form-group {
    margin-bottom: calc(14rem / 16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(24rem / 16);
    }
}

/* control label */
.control-label {
    margin-bottom: calc(5rem / 16);
    font-size: calc(16rem / 16);
    line-height: calc(18/16);
}

/* datepicker nav */
.ui-datepicker.ui-datepicker .ui-icon {
    text-indent: unset;
    overflow: unset;
    padding: calc(8rem / 16);
}
.ui-datepicker.ui-datepicker .ui-datepicker-next .ui-icon {
    transform: rotate(180deg);
}
.ui-datepicker.ui-datepicker .ui-datepicker-next-hover {
    right: 2px;
    top: 2px;
}
.ui-datepicker.ui-datepicker .ui-datepicker-prev-hover {
    left: 2px;
    top: 2px;
}
.ui-datepicker .ui-datepicker-next:hover .ui-icon,
.ui-datepicker .ui-datepicker-prev:hover .ui-icon {
    color: var(--color-primary);
}