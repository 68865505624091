@media screen and (max-width: 767px) {
/*    html.has-overflow-hidden, .has-overflow-hidden body {
        overflow: hidden;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }*/
    .navbar:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        box-shadow: 0 calc(5rem / 16) calc(15rem / 16) 0 rgba(0,0,0,0.1);
        z-index: 1;
        pointer-events: none;
    }
    .navbar-collapse {
        position: absolute;
        height: calc(100vh - calc(70rem / 16));
        background: #fff;
        top: calc(70rem / 16);
        left: 0;
        right: 0;
        transform: translateX(-100%);
        transition: transform 250ms ease-in-out;
        overflow-y: auto;
        overflow-x: hidden;
        -webkit-overflow-scrolling:touch;
    }
    .is-open .navbar-collapse {
        transform: translateX(0);
    }
    .navbar-nav {
        display: block;
        width: 100%;
        margin: 0;
        text-align: center;
        padding: calc(35rem / 16) 0;
    }
    .navbar-nav .nav-item + .nav-item {
        margin-top: calc(16rem / 16);
    }

    .navbar-nav li.is-open > a {
        color: var(--color-primary);
    }

    /* toggle button */
    .navbar-toggler {
        font-size: calc(10rem / 16);
        padding: calc(8rem / 16);
        margin-right: calc(-8rem / 16);
    }
    .navbar-toggler__text {
        margin-top: calc(8rem / 16);
    }
    .navbar-toggler__bar {
        width: calc(25rem / 16);
        height: calc(2rem / 16);
        background: var(--color-grey);
        transition: transform 250ms ease-in-out, opacity 250ms ease-in-out;
        border-radius: 1px;
        margin-left: auto;
        margin-right: auto;
    }
    .navbar-toggler__bar + .navbar-toggler__bar {
        margin-top: calc(6rem / 16);
    }

    .is-open .navbar-toggler__bar:nth-child(2) {
        opacity: 0;
    }
    .is-open .navbar-toggler__bar:nth-child(1) {
        transform: rotate(-45deg) translateX(-6px) translateY(4px) scaleX(1.4);
    }
    .is-open .navbar-toggler__bar:nth-child(3) {
        transform: rotate(45deg) translateX(-8px) translateY(-5px) scaleX(1.4);
    }
    .nav-item__subnav {
        display: none;
    }
    .navbar-nav li.is-open .nav-item__subnav {
        display: block;
    }
    .nav-item__subnav li + li {
        margin-top: calc(8rem / 16);
    }

    .nav-link__icon {
        font-size: calc(8rem / 16);
        transition: transform 200ms ease-in-out;
        padding: calc(15rem / 16);
        margin: calc(-15rem / 16) calc(-10rem / 16);
    }
    .nav-item.is-open .nav-link__icon {
        transform: rotate(180deg);
    }

    /* navbar brand */
    .navbar-brand {
        width: calc(110rem / 16);
        line-height: 1;
    }
    .navbar-brand > img {
        max-height: 100%;
        width: auto;
    }
}