/* no gutter */
.row.row--gutter-width-0 {
    margin-left:0;
    margin-right:0;
}
.row--gutter-width-0 > .col,
.row--gutter-width-0 > [class^="col"],
.row--gutter-width-0 > [class*=" col"] {
    padding-left:0;
    padding-right:0;
}


/* pixel gutters */

.row.row--gutter-width-10 {
    margin-left:-5px;
    margin-right:-5px;
}
.row--gutter-width-10 > .col,
.row--gutter-width-10 > [class^="col"],
.row--gutter-width-10 > [class*=" col"] {
    padding-left:5px;
    padding-right:5px;
}
.row.row--gutter-width-20 {
    margin-left:-10px;
    margin-right:-10px;
}
.row--gutter-width-20 > .col,
.row--gutter-width-20 > [class^="col"],
.row--gutter-width-20 > [class*=" col"] {
    padding-left:10px;
    padding-right:10px;
}

/* Vertical gutter */
.row--vertical-gutter-10 {
    margin-top: calc(-10rem/16);
}
.row--vertical-gutter-10 > [class^="col"],
.row--vertical-gutter-10 > [class*=" col"] {
    margin-top: calc(10rem/16);
}
.row--vertical-gutter-16 {
    margin-top: calc(-16rem/16);
}
.row--vertical-gutter-16 > [class^="col"],
.row--vertical-gutter-16 > [class*=" col"] {
    margin-top: calc(16rem/16);
}
.row--vertical-gutter-24 {
    margin-top: calc(-24rem/16);
}
.row--vertical-gutter-24 > [class^="col"],
.row--vertical-gutter-24 > [class*=" col"] {
    margin-top: calc(24rem/16);
}
.row--vertical-gutter-30 {
    margin-top: calc(-30rem/16);
}
.row--vertical-gutter-30 > [class^="col"],
.row--vertical-gutter-30 > [class*=" col"] {
    margin-top: calc(30rem/16);
}
.row--vertical-gutter-32 {
    margin-top: calc(-32rem/16);
}
.row--vertical-gutter-32 > [class^="col"],
.row--vertical-gutter-32 > [class*=" col"] {
    margin-top: calc(32rem/16);
}
.row--vertical-gutter-40 {
    margin-top: calc(-40rem/16);
}
.row--vertical-gutter-40 > [class^="col"],
.row--vertical-gutter-40 > [class*=" col"] {
    margin-top: calc(40rem/16);
}