.clickable { cursor: pointer; }

/*Text Helper*/
.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


/*Text Color Helper*/
.text-primary-hover:hover { color: var(--color-primary); }
.text-white { color: #ffffff; }
.text-color-default { color: var(--color-text-default); }
.text-grey { color: var(--color-grey); }
.text-light { color: var(--color-light); }

.text-green { color: var(--color-green); }
.text-blue { color: var(--color-blue); }
.text-blue-light { color: var(--color-blue-light); }
.text-orange { color: var(--color-orange); }

/*Background Color Helper*/
.bg-grey {background: #F0F0F0;}



.link-arrow {
    font-size: calc(16rem / 16);
    letter-spacing: calc(2rem / 16);
}
.link-arrow:after {
    content: var(--icon-arrow);
    font-family: iconfont;
    display: inline-block;
    transform: scaleX(-1);
    color: var(--color-primary);
    font-size: calc(12rem / 16);
    margin-left: calc(6rem / 16);
}

.link-with-icon {
    font-weight: var(--font-weight-bold);
    font-size: calc(16rem / 16);
    letter-spacing: calc(2rem / 16);
}
.link-with-icon__icon {
    vertical-align: -0.25em;
    font-size: calc(18rem / 16);
    margin-left: calc(5rem / 16);
}

.img-grayscale {
    filter: grayscale(100%);
    opacity: .4;
    transition: filter 250ms ease-in-out, opacity 250ms ease-in-out;
}
.clickable:hover .img-grayscale,
.img-grayscale:hover {
    filter: grayscale(0);
    opacity: 1;
}

.logo-text {
    width: calc(140rem / 16);
    max-width: 100%;
}
.logo-text--lg {
    width: calc(300rem / 16);
}