.circle-teaser {
    line-height: calc(31/24);

    @media screen and (min-width: 768px) {
        font-size: calc(24rem / 16);
        letter-spacing: calc(3rem / 16);
    }
}
.circle-teaser__title {
    letter-spacing: calc(4rem / 16);
    margin-top: calc(32rem / 16);

    @media screen and (min-width: 768px) {
        letter-spacing: calc(5.2rem / 16);
        margin-top: calc(42rem / 16);
        font-size: calc(34rem / 16);
    }

    @media screen and (min-width: 1200px) {
        font-size: calc(42rem/16);
        line-height: calc(54/42);
    }
}
.circle-teaser__img {
    position: relative;
    width: calc(180rem / 16);
    max-width: 100%;
    transition: transform 300ms cubic-bezier(0.68, -0.55, 0.27, 1.55);

    @media screen and (min-width: 768px) {
        width: calc(240rem / 16);
    }
}
.circle-teaser.clickable:hover .circle-teaser__img {
    transform: translateY(calc(15rem / 16));
}
.circle-teaser__img:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: var(--color-red);
    border-radius: 50%;
    opacity: .5;
}

.circle-teaser__link {
    color: var(--color-red);
}

.circle-teaser--green .circle-teaser__link { color: var(--color-green);}
.circle-teaser--green .circle-teaser__img:after { background: var(--color-green);}

.circle-teaser--blue .circle-teaser__link { color: var(--color-blue);}
.circle-teaser--blue .circle-teaser__img:after { background: var(--color-blue);}

.circle-teaser--gold .circle-teaser__link { color: #d8bb1e;}
.circle-teaser--gold .circle-teaser__img:after { background: #d8bb1e;}

.circle-teaser--no-overlay .circle-teaser__img:after {
    content: none;
}


/* small teaser */
.circle-teaser.circle-teaser--sm {
    font-size: calc(16rem / 16);
    line-height: calc(22/16);
    letter-spacing: calc(2rem / 16);

    @media screen and (min-width: 768px) {
        line-height: calc(32/18);
        font-size: calc(18rem / 16);
        max-width: calc(490rem / 16);
        margin: 0 auto;
    }
}
.circle-teaser--sm .circle-teaser__title {
    font-size: calc(28rem / 16);
    line-height: calc(35/28);
    letter-spacing: calc(2rem / 16);
}
.circle-teaser--sm .circle-teaser__img {
    @media screen and (min-width: 768px) {
        width: calc(200rem / 16);
    }
}