.page-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    transition: opacity 200ms ease-in-out, visibility 200ms ease-in-out;
    background: rgba(0, 0, 0, .4);
    z-index: 13;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: calc(10rem / 16);
    visibility: hidden;

    @media screen and (min-width: 768px) {
        padding: calc(20rem / 16);
    }
}
.page-overlay__close {
    position: absolute;
    top: 0;
    right: 0;
    font-size: calc(18rem / 16);
    padding: calc(10rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(26rem / 16);
        padding: calc(20rem / 16);
    }
}
.is-active.page-overlay {
    opacity: 1;
    visibility: visible;
}
.page-overlay__content {
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    background: #fff;
    width: 100%;
    max-width: calc(1200rem / 16);
    padding: calc(30rem / 16) calc(10rem / 16);
    border-radius: calc(6rem / 16);
    box-shadow: 0 0 calc(15rem / 16) rgba(0,0,0,.1);
    position: relative;

    @media screen and (min-width: 768px) {
        padding: calc(50rem / 16) calc(30rem / 16);
    }
}
.page-overlay__content__title {
    font-size: calc(26rem/16);
    margin-bottom: 0;

    @media screen and (min-width: 768px) {
        font-size: calc(58rem/16);
    }
}
.page-overlay__content__subtitle {
    font-size: calc(20rem/16);

    @media screen and (min-width: 768px) {
        font-size: calc(42rem/16);
    }
}