.teaser {
    border-radius: calc(6rem / 16);
    border: 1px solid var(--color-light);
    font-size: calc(18rem / 16);
    line-height: calc(30/18);
}
.teaser__body {
    padding: calc(15rem / 16);
    word-break: break-word;

    @media screen and (min-width: 1200px) {
        padding: calc(28rem / 16);
    }
}
.teaser__body__subtitle {
    letter-spacing: calc(2.25rem / 16);
}
.teaser__body__title {
    font-size: calc(24rem / 16);
    line-height: calc(26/24);
    letter-spacing: calc(2rem / 16);

    @media screen and (min-width: 1200px) {
        font-size: calc(28rem / 16);
        line-height: calc(35/28);
        letter-spacing: calc(3.5rem / 16);
    }
}
.teaser__img {
    transition: transform 200ms ease-in-out;
}
.teaser:hover .teaser__img {
    transform: scale(1.04);
}