.btn {
    font-size: calc(20rem / 16);
    line-height: calc(26/20);
    font-weight: var(--font-weight-bold);
    padding: calc(7rem / 16) calc(20rem / 16);
    letter-spacing: normal;

    @media screen and (min-width: 768px) {
        padding: calc(12rem / 16) calc(20rem / 16) calc(14rem / 16);
        font-size: calc(24rem / 16);
        line-height: calc(31/24);
    }
}

.btn.btn-sm {
    @media screen and (min-width: 768px) {
        font-size: calc(20rem / 16);
        padding: calc(7rem / 16) calc(15rem / 16) calc(8rem / 16);
    }
}

.btn.btn-lg {
    padding: calc(10rem / 16) calc(50rem / 16) calc(11rem / 16);
    font-size: calc(22rem / 16);
    line-height: calc(31/24);

    @media screen and (min-width: 768px) {
        font-size: calc(24rem / 16);
        padding: calc(12rem / 16) calc(70rem / 16) calc(14rem / 16);
    }
}
.btn-breakable { white-space: normal;}

.btn-no-styling,
.btn.btn-no-styling {
    border: none;
    background: none;
    text-align: left;
    padding: 0;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
    cursor: pointer;
}

.btn-outline-white:hover {
    color: var(--color-default)
}


/* button icon */
.btn__icon{
    margin-right: calc(14rem / 16);
    font-size: calc(20rem / 16);
    vertical-align: -.1em;
}
.btn__icon.btn__icon--right{
  margin-right: 0;
  margin-left: calc(14rem/16);
}