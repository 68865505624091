.modal-lg {
    max-width: calc(1362rem / 16);
}
.modal-content {
    border-radius: calc(6rem / 16);
    border: 1px solid var(--color-light);
}
.modal-header {
    border-bottom: 0;
    padding: calc(16rem / 16) calc(24rem / 16) calc(5rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(30rem / 16) 0 calc(5rem / 16);
    }
}
.modal-title {
    letter-spacing: calc(4rem / 16);
}
.modal-body {
    padding: calc(12rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(24rem / 16) calc(28rem / 16);
    }
}
.modal .close {
    position: absolute;
    right: 0;
    top: 0;
    margin: 0;
    padding: calc(16rem / 16);
    font-size: calc(14rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(32rem / 16);
    }
}

/* cookie-modal */
.cookie-modal .modal-header {
    @media screen and (min-width: 768px) {
        padding: calc(30rem / 16) calc(28rem / 16) calc(5rem / 16);
    }
}
.cookie-modal .modal-header .close {
    padding: calc(16rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(32rem / 16);
    }
}
.cookie-modal .modal-header .close:before {
    content: var(--icon-close);
    font-family: iconfont;

}
.cookie-modal.cookie-modal .modal-header .close > span {
    display: none;
}