.footer {
    margin-top: calc(48rem / 16);
    letter-spacing: calc(.5rem / 16);
    font-size: calc(18rem / 16);
    line-height: calc(23/18);
    padding: calc(15rem / 16) 0;
    border-top: calc(1rem / 16) solid var(--color-light-grey);

    @media screen and (min-width: 768px) {
        margin-top: calc(90rem / 16);
        padding: calc(45rem / 16) 0 calc(70rem / 16);
    }
}
.console ~ .footer {
    @media screen and (max-width: 767px) {
        padding-bottom: calc(75rem / 16);
    }
}
.footer__logo {
    max-width: 100%;
    width: calc(260rem / 16);
    height: auto;

    @media screen and (min-width: 768px) {
        width: calc(360rem / 16);
    }
}
.footer__hl {
    font-size: calc(27rem / 16);
    letter-spacing: normal;
}
.footer__social-link {
    font-size: calc(58rem / 16);
}
.footer__links .list-inline-item:not(:last-child) {
    @media screen and (min-width: 768px) {
        margin-right: calc(35rem / 16);
    }
}
.footer .small {
    font-size: calc(16rem / 16);
}

.site-by-elements {
    margin-top: calc(10rem / 16);

    @media screen and (min-width: 768px){
        margin-top: 0;
        position: relative;
        top: calc(-25rem / 16);
    }
}

.sponsor-logo {
    padding: 0 calc(5rem / 16);
}