.slide-sm {
    margin-top: calc(25rem / 16);
    margin-bottom: calc(25rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(30rem/16);
        margin-bottom: calc(30rem/16);
    }
}

.slide-md {
    margin-top: calc(30rem / 16);
    margin-bottom: calc(30rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(50rem/16);
        margin-bottom: calc(50rem/16);
    }
}

.slide {
    margin-top: calc(50rem / 16);
    margin-bottom: calc(50rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(90rem/16);
        margin-bottom: calc(90rem/16);
    }
}

.slide__head {
    padding-bottom: calc(15rem / 16);

    @media screen and (min-width: 768px) {
        padding-bottom: calc(50rem / 16);
    }
}