.download-teaser {
    border: 1px solid var(--color-light);
    background-color: rgba(255,255,255,0.6);
    border-radius: calc(6rem / 16);
    letter-spacing: calc(1rem / 16);
    line-height: calc(30/18);
}
.download-teaser__body {
    padding: calc(10rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(10rem / 16) calc(16rem / 16);
        padding-left: 0;
    }
}
.download-teaser__body__title {
    font-size: calc(22rem / 16);
    letter-spacing: calc(1.8rem / 16);
    line-height: calc(42/32);


    @media screen and (min-width: 768px) {
        font-size: calc(26rem / 16);
        letter-spacing: calc(2.9rem / 16);
    }

    @media screen and (min-width: 1400px) {
        font-size: calc(32rem / 16);
        letter-spacing: calc(4rem / 16);
    }
}