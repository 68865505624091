.event-teaser {
    border: 1px solid var(--color-light);
    border-radius: calc(6rem / 16);
    overflow: hidden;
    position: relative;
    font-size: calc(16rem / 16);
    line-height: calc(21/16);
    letter-spacing: calc(2rem / 16);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.event-teaser:before {
    content: '';
    background: #fff;
    opacity: .6;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: opacity 250ms ease-in-out;
}
.event-teaser:hover:before {
    opacity: .7;
}
.event-teaser__content {
    position: relative;
    padding: calc(12rem / 16) calc(15rem / 16);
    min-height: calc(150rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(22rem / 16) calc(24rem / 16);
        min-height: calc(250rem / 16);
    }
}
.event-teaser__content__title {
    font-size: calc(22rem / 16);
    line-height: calc(32/24);
    letter-spacing: calc(1.5rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(24rem / 16);
        letter-spacing: calc(2.4rem / 16);
    }
}
.event-teaser__content__date {
    font-size: calc(18rem / 16);
    line-height: calc(42/20);
    letter-spacing: calc(1.5rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(20rem / 16);
        letter-spacing: calc(2.5rem / 16);
    }
}