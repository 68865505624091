/* basic pagination styles in ..bootstrap/scss/_variables.custom.scss */
.pagination {
    font-size: calc(20rem / 16);
    margin: calc(15rem / 16) 0;

    @media screen and (min-width: 768px) {
        margin: calc(30rem / 16) 0;
        font-size: calc(22rem / 16);
    }
}
.page-link__icon {
    font-size: calc(12rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(14rem / 16);
    }
}