.price-info {
    border: 1px solid var(--color-light);
    border-radius: calc(6rem / 16);
    background-color: rgba(255,255,255,0.6);
    padding: calc(5rem / 16);

    @media screen and (min-width: 768px){
        letter-spacing: calc(2rem / 16);
        min-width: calc(175rem / 16);
    }
}
.price-info__value {
    font-size: calc(32rem / 16);
    line-height: calc(42/32);
    letter-spacing: calc(2rem / 16);

    @media screen and (min-width: 768px) {
        letter-spacing: calc(4rem / 16);
    }
}