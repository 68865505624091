.calendar-container {
    position: relative;
    width: calc(1250rem / 16);
    margin: 0 auto;
    max-width: 100%;
    padding: calc(5rem / 16) calc(16rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(50rem / 16) 0;
    }
}
.calendar-container:before {
    content: attr(data-year);
    font-size: calc(80rem / 16);
    line-height: 1;
    color: var(--color-light-grey);
    margin-bottom: 15px;
    display: block;

    @media screen and (min-width: 768px){
        position: absolute;
        left: 0;
        bottom: calc(20rem / 16);
        transform: rotate(90deg) translateY(100%) translateX(-100%);
        transform-origin: left bottom;
        font-size: calc(160rem / 16);
        letter-spacing: calc(15rem / 16);
    }
}
.calendar {
    width: 100%;

    @media screen and (min-width: 768px) {
        table-layout: fixed;
    }
}
.calendar__head th {
    color: #7D7D7D;
    font-size: calc(16rem / 16);
    font-weight: var(--font-weight-bold);
    letter-spacing: calc(4.6rem / 16);
    line-height: calc(21/16);
    padding: calc(8rem / 16) calc(15rem / 16);
    text-align: center;
}
.calendar__day {
    position: relative;
    border-radius: calc(2rem / 16);
    min-height: calc(80rem / 16);

    @media screen and (min-width: 768px) {
        border: calc(8rem / 16) solid #F0F0F0;
        min-height: unset;
        height: calc(120rem / 16);
    }
}
.calendar__day:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
}

.calendar__day__count {
    position: absolute;
    right: calc(10rem / 16);
    bottom: calc(10rem / 16);
    color: var(--color-light-grey);
    font-size: calc(26rem / 16);
    line-height: calc(34/26);
    letter-spacing: calc(1.44rem / 16);
    transition: 200ms color ease-in-out;
}

/* month buttons */
.calendar__nav-btn {
    font-size: calc(16rem / 16);
    line-height: calc(21/16);
    letter-spacing: calc(4rem / 16);
    padding: 10px 16px 10px 20px;
    background: #fff;
    text-transform: uppercase;
    text-align: center;
    border-radius: calc(2rem / 16);
    margin: 0 calc(3rem / 16);
    position: relative;
}
.calendar__nav-btn.is-active,
.calendar__nav-btn:hover {
    background: var(--color-primary);
    color: #fff;
}
.calendar__nav-btn__year {
    display: none;
    color: var(--color-light);
    font-size: calc(16rem / 16);
    line-height: calc(21/16);
    letter-spacing: calc(4.6rem / 16);
    /*text-shadow: 4px 4px 10px 0 rgba(0,0,0,0.58);*/
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
    @media screen and (min-width: 768px) {
        display: block;
    }
}

/* placeholder days */
.calendar__day.calendar__day--placeholder {
    cursor: default;
}
.calendar__day.calendar__day--placeholder:before {
    content: none;
}

/* current day */
.calendar__day.is-today:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: calc(2rem / 16) solid #981711;
    box-shadow: 0 calc(2rem / 16) calc(4rem / 16) 0 rgba(0,0,0,0.5);
    border-radius: calc(2rem / 16);
    pointer-events: none;
}

.calendar__day__entry {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    padding: calc(2rem / 16) calc(45rem / 16) calc(2rem / 16) calc(8rem / 16);
    line-height: calc(24/18);

    @media screen and (min-width: 768px) {
        padding: calc(2rem / 16) calc(8rem / 16);
    }
}

/* detail box */
.is-active.calendar__day__detail{
    opacity: 1;
    transform: translateX(-50%) translateY(-50%);

    @media screen and (min-width: 768px) {
        transform: translateX(-50%) translateY(-50%) scale(1);
    }
}
.calendar__day__detail {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translateX(100%) translateY(-50%);
    opacity: 0;
    background-color: #FFFFFF;
    width: calc(100% - calc(32rem / 16));
    z-index: 4;
    transition: transform 200ms ease-in-out, opacity 200ms ease-in-out;
    border: calc(3rem / 16) solid var(--color-primary);
    border-radius: calc(2rem / 16);
    box-shadow: 0 calc(2rem / 16) calc(4rem / 16) 0 rgba(0,0,0,0.5);
    font-size: calc(18rem / 16);
    letter-spacing: calc(1.8rem / 16);
    padding: calc(50rem / 16) calc(18rem / 16) calc(14rem / 16);

    @media screen and (min-width: 768px) {
        margin: 0;
        position: absolute;
        width: calc(480rem / 16);
        min-height: 100%;
        transform: translateX(-50%) translateY(-50%) scale(0);
        padding: calc(14rem / 16) calc(18rem / 16);
    }
}
.calendar__day__detail--green { border-color: var(--color-green); }
.calendar__day__detail--blue { border-color: var(--color-blue); }
.calendar__day__detail--blue-light { border-color: var(--color-blue-light); }

.calendar__day__detail__close {
    position: absolute;
    right: calc(12rem / 16);
    top: calc(12rem / 16);
    font-size: calc(16rem / 16);
    color: var(--color-light-grey);

    @media screen and (min-width: 768px) {
        right: calc(15rem / 16);
        top: calc(15rem / 16);
    }
}
.calendar__day__detail__title {
    font-size: calc(24rem / 16);
    line-height: calc(31/24);
    letter-spacing: normal;
    margin-bottom: calc(10rem / 16);
}
.calendar__day__detail__facts {
    font-size: calc(14rem / 16);
    letter-spacing: calc(1.4rem / 16);
    line-height: calc(26/14);
}
.calendar__day__detail__time {
    font-size: calc(36rem / 16);
    letter-spacing: calc(3.6rem / 16);
}
.calendar__day__detail__day {
    position: absolute;
    right: calc(15rem / 16);
    bottom: calc(12rem / 16);
    font-size: calc(36rem / 16);
    letter-spacing: calc(5rem / 16);
}

.calendar-nav {
    margin: 0 calc(-16rem / 16) calc(16rem / 16);
    position: relative;

    @media screen and (min-width: 768px) {
        margin: 0;
        margin-bottom: calc(50rem / 16);
    }
}
.calendar-nav__arrow {
    padding: calc(5rem / 16) calc(10rem / 16);
    font-size: calc(15rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(5rem / 16) calc(16rem / 16);
    }
}
@media screen and (max-width: 767px) {
    .calendar-nav__months {
        overflow-x: auto;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
    }
    .calendar-container:after {
        content: '';
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(255,255,255,.8);
        opacity: 0;
        visibility: hidden;
        transition: opacity 200ms ease-in-out;
        z-index: 3;
    }
    .calendar-container.has-overlay .calendar {
        -webkit-overflow-scrolling: unset; /* fix iphoneX bug */
    }
    .calendar-container.has-overlay:after {
        visibility: visible;
        opacity: 1;
    }
    .calendar {
        max-height: calc(500rem / 16);
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        display: block;
        padding-bottom: calc(10rem / 16);
    }
    .calendar tbody,
    .calendar__week {
        display: block;
    }
    .calendar__head {
        display: none;
    }
    .calendar__day {
        display: block;
        width: 100%;
        margin-top: calc(4rem / 16);
    }
    .calendar__day:not(.has-entry) {
        display: none;
    }
}