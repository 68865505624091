.hero-img {
    position: relative;
}
.hero-img:before {
    content: var(--icon-k-sign);
    font-family: iconfont;
    position: absolute;
    top: 0;
    left: 0;
    font-size: calc(460rem / 16);
    z-index: -1;
    color: #F7F7F7;
    line-height: 1;

    @media screen and (min-width: 768px){
        left: 50%;
        transform: translateX(-50%);
        font-size: calc(1555rem / 16);
    }
}
.hero-img__img {
    overflow: hidden;
    border-radius: calc(2rem / 16);
    position: relative;
}
.hero-img__img:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(311.7deg, rgba(159,28,20,0) 0%, rgba(152,23,17,0.45) 100%);
}
.hero-img--full-height-xs .hero-img__img {
    @media screen and (max-width: 767px){
        height: calc(100vh - calc(70rem / 16));
        border-radius: 0;
    }
}
.hero-img__text {
    position: absolute;
    top: 43%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    padding: 0 calc(16rem / 16);
    z-index: 1;

    @media screen and (min-width: 768px) {
        padding: 0 calc(24rem / 16);
    }

    @media screen and (min-width: 1200px) {
        padding: 0 calc(85rem / 16);
    }
}
.hero-img__text__title {
    font-size: calc(36rem / 16);
    line-height: calc(46/36);

    @media screen and (min-width: 768px) {
        font-size: calc(58rem / 16);
        line-height: calc(74/58);
    }
}
.hero-img__text__subtitle {
    font-size: calc(38rem / 16);
    line-height: calc(58/45);

    @media screen and (min-width: 1200px) {
        font-size: calc(45rem / 16);
    }
}
.hero-img__arrow {
    position: absolute;
    bottom: calc(24rem / 16);
    left: calc(24rem / 16);
    width: calc(60rem / 16);
    height: calc(60rem / 16);
    border-radius: 50%;
    box-shadow: 0 calc(2rem / 16) calc(4rem / 16) 0 rgba(0,0,0,0.5);
    background: #fff;
    color: var(--color-primary);
    transition: color 200ms ease-in-out, background 200ms ease-in-out;
    z-index: 1;

    @media screen and (min-width: 1400px){
        left: 5.5%;
    }
}
.hero-img__arrow__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}
.hero-img__arrow.slick-next {
    left: auto;
    right: calc(24rem / 16);

    @media screen and (min-width: 1400px){
        right: 6%;
    }
}
.hero-img__arrow.slick-next .hero-img__arrow__icon {
    transform: translateX(-50%) translateY(-50%) scaleX(-1);
}
.hero-img__arrow.slick-disabled {
    color: var(--color-text-muted);
    pointer-events: none;
}
.hero-img__arrow:not(.slick-disabled):hover {
    background: var(--color-primary);
    color: #fff;
}

/* text positions */
.hero-img__text--center {
    top: 50%;
    transform: translateY(-50%);
}

/* text sizes */
.hero-img__text--lg .hero-img__text__title {
    @media screen and (min-width: 768px) {
        font-size: calc(64rem / 16);
        line-height: calc(97/75);
    }

    @media screen and (min-width: 1200px) {
        font-size: calc(75rem / 16);
    }
}

/* hero img content */
.hero-img__content {
    padding: 0 calc(16rem / 16) 0;
    font-size: calc(18rem / 16);
    line-height: calc(32/20);
    letter-spacing: calc(2rem / 16);
    position: relative;

    @media screen and (min-width: 768px) {
        padding: calc(30rem / 16) 0;
        font-size: calc(20rem / 16);
    }
}

.hero-img__content__title {
    letter-spacing: calc(2.8rem / 16);
}

/* hero slider */
.hero-slider {
    position: relative;
}
.hero-slider__arrow-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}
.hero-slider .hero-img:before {
    content: none;
}
.hero-slider .slick-dots {
    position: absolute;
    left: 0;
    right: 0;
    bottom: calc(-20rem / 16);
}
.hero-img__box {
    position: relative;
    margin: -45% calc(16rem / 16) calc(15rem / 16);
    max-width: 100%;
    box-shadow: 0 calc(5rem / 16) calc(15rem / 16) 0 rgba(0,0,0,0.2);
    padding: calc(24rem / 16) calc(16rem / 16);
    border-radius: calc(6rem / 16);
    font-size: calc(18rem / 16);
    letter-spacing: calc(1.8rem / 16);

    @media screen and (min-width: 768px){
        max-width: calc(1300rem / 16);
        padding: calc(30rem / 16) calc(24rem / 16);
        margin: -12% 12% calc(15rem / 16);
    }

    @media screen and (min-width: 1200px){
        margin: -7% 12% calc(15rem / 16);
    }

    @media screen and (min-width: 1400px) {
        padding: calc(45rem / 16) calc(95rem / 16);
    }
}
.hero-img__box__title {
    font-size: calc(30rem / 16);
    line-height: calc(32/30);
    letter-spacing: calc(3rem / 16);
}
.hero-img__box__date {
    font-size: calc(60rem / 16);
    line-height: 1;

    @media screen and (min-width: 768px) {
        font-size: calc(86rem / 16);
        letter-spacing: calc(14rem / 16);
    }

    @media screen and (min-width: 1400px) {
        font-size: calc(100rem / 16);
    }
}
.hero-img__box__date__month {
    font-size: calc(32rem / 16);
    letter-spacing: calc(5.5rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(38rem / 16);
        letter-spacing: calc(7.7rem / 16);
    }

    @media screen and (min-width: 1400px) {
        font-size: calc(45rem / 16);
    }
}

.hero-slider__pause {
    position: absolute;
    top: calc(15rem / 16);
    right: calc(20rem / 16);
    color: #fff;
    z-index: 1;
    height: calc(24rem / 16);
    border-color: transparent transparent transparent #ffffff;
    transition: 200ms border-style ease-in-out, 200ms border-width ease-in-out, opacity 200ms ease-in-out;
    border-style: double;
    border-width: 0 0 0 calc(20rem / 16);
    opacity: .8;

    @media screen and (min-width: 768px) {
        top: calc(20rem / 16);
        right: calc(25rem / 16);
    }
}

.hero-slider__pause.is-paused {
    border-style: solid;
    border-width: calc(12rem / 16) 0 calc(12rem / 16) calc(20rem / 16);
}
.hero-slider__pause:hover {
    opacity: 1;
}
