.anchor-nav.navbar {
    background-color: var(--color-primary-contrast);
    padding: 5px 0px;
    height: 70px;
}
@media screen and (max-width: 767px) {
    .anchor-nav.navbar {
        height: 50px;
    }
}
@media screen and (max-width: 767px) {
    .anchor-nav.navbar .navbar-nav {
        padding: 0;
        text-align: left;
    }
}
.anchor-nav.navbar:after {
    content: none;
}


.anchor-nav .navbar-nav .nav-item + .nav-item {
    margin-top: 0;
}
.anchor-nav .nav-link {
    padding: 8px 0;
}
.list-inline-item:not(:last-child) {
    margin-right: 30px;
}
.nav-link.active {
    color: var(--color-primary);
}

.on-page-nav {
    position: fixed;
    top: 70px;
    left: 0;
    right: 0;
    bottom: auto;
    //background: #fff;
    z-index: 2;
    box-shadow: 0 0 0.25rem rgba(0, 0, 0, .3);
    transform: translateY(-120%);
    transition: transform 120ms ease-out;
    will-change: transform;
}
@media screen and (min-width: 768px) and (max-width: 1399px) {
    .on-page-nav {
        top: 100px;
        left: 0;
    }
}
@media screen and (min-width: 1400px) {
    .on-page-nav {
        top: 100px;
        left: 0;
    }
}
@media screen and (max-width: 767px) {
    .on-page-nav {
        white-space: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
    }
}
.on-page-nav.is-visible {
    transform: translateY(0);
}
.on-page-nav:not(.is-visible) {
    transform: translateY(-200px);
}
@media screen and (min-width: 768px) {
    .is-affiliate .on-page-nav.on-page-nav {
        left: 0;
    }
}
@media screen and (max-width: 767px) {
    .is-affiliate .on-page-nav.on-page-nav {
        top: 0;
        transform: translateY(0);
    }
}
.on-page-nav__list {
    flex-direction: row;
}
.on-page-nav__list > li {
    display: inline-block;
    margin-right: 1.25rem;
}
@media screen and (min-width: 768px) and (max-width: 1199px) {
    .on-page-nav__list > li {
        margin-right: 0.75rem;
    }
}
.on-page-nav.navbar a.nav-link.on-page-nav__link {
    position: relative;
    display: inline-block;
    font-size: 14px;
    margin: 0;
    white-space: nowrap;
    font-family: var(--font-default);
    color: var(--color-text-muted);
}
@media screen and (min-width: 768px) and (max-width: 1199px) {
    .on-page-nav.navbar a.nav-link.on-page-nav__link {
        font-size: 12px;
    }
}
@media screen and (min-width: 1200px) {
    .on-page-nav.navbar a.nav-link.on-page-nav__link {
        font-size: 14px;
    }
}
.on-page-nav.navbar a.nav-link.on-page-nav__link.active {
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
    color: var(--color-primary);
}
