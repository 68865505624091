.media-item {
    position: relative;
}
.media-item__icon {
    position: absolute;
    right: calc(8rem / 16);
    bottom: calc(8rem / 16);
    font-size: calc(8rem / 16);
    transition: color 200ms ease-in-out;

    @media screen and (min-width: 768px){
        right: 1rem;
        bottom: 1rem;
        font-size: 1rem;
    }
}
.media-item:hover .media-item__icon {
    color: var(--color-primary);
}