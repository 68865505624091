.ticket {
    background: url(/static/build/img/ticket-bg.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-width: calc(200rem / 16);
    height: calc(150rem / 16);
    filter: drop-shadow( 0 calc(5rem / 16) calc(15rem / 16) rgba(0, 0, 0, .2));
    padding: calc(42rem / 16) calc(50rem / 16);
    font-size: calc(14rem / 16);
    line-height: 0.9;
    letter-spacing: calc(1.8rem / 16);
}
.ticket__price {
    font-size: calc(48rem / 16);
    position: relative;
}
.ticket__price__info{
    position: absolute;
    font-size: calc(14rem / 16);
    top: calc(6rem / 16);
    right: calc(-1rem / 16);
}